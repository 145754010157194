import { QueryClient, useMutation } from '@tanstack/vue-query'

export function useOptimisticMutation<TData>(
   key: string | string[],
   queryClient: QueryClient,
   mutationFn: (data: TData) => any,
   optimisticFn,
   optimisticFnCallback?
) {
   return useMutation(data => mutationFn(data), {
      onMutate: async (data: TData) => {
         // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
         await queryClient.cancelQueries(key)

         // Snapshot the previous value
         const previousData = queryClient.getQueryData(key)

         // Optimistically update to the new value
         queryClient.setQueryData(key, old => optimisticFn(old, data))

         if (optimisticFnCallback) {
            optimisticFnCallback(data)
         }

         // Return a context object with the snapshotted value
         return { previousData }
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, data, context: any) => {
         queryClient.setQueryData(key, context.previousData)
      },
      // Always refetch after error or success:
      onSettled: () => {
         queryClient.invalidateQueries(key)
      }
   })
}
